export const firebaseConfig = {
  apiKey: "AIzaSyAtWzRDg8JTPawwuvG_cOBzjg76QvVC4UQ",
  authDomain: "richman-402411.firebaseapp.com",
  databaseURL: "https://richman-402411.firebaseio.com",
  projectId: "richman-402411",
  storageBucket: "richman-402411.appspot.com",
  messagingSenderId: "891401240858",
  appId: "1:891401240858:web:9b8518b6af9a0b725deca3"
};

export const vapidKey = `BMO5rIMZWgBabvRh9tAuSFww1d9c_clelEBg4OIGDXhjvMZrw_FnK_YDspgJKDA7Kn9tSPCarNEAcoISzdJEd7U`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}